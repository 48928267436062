import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/common.less'
import './assets/style/bigdata.less'
import './assets/style/gis.less'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import moment from 'moment';    //JavaScript 日期处理类库
moment.locale('zh-cn');
import * as echarts from 'echarts'
import scroll from 'vue-seamless-scroll'
import 'amfe-flexible'
// // let echarts = require('echarts/lib/echarts')
Vue.prototype.$echarts = echarts;
Vue.use(scroll)

Vue.use(ElementUI);
Vue.prototype.$moment = moment;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
