import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'bigdata',
    component: () => import('../views/bigdata.vue'),
    redirect:'/bigindex',
    children: [
      {
        path: 'bigindex',
        component: resolve => require(['@/views/bigpages/bigindex'], resolve), //首页
        name: 'bigindex',
      }
    ]
  },
  {
    path: '/gis',
    name: 'gis',
    component: () => import('../views/gis.vue')
  },
  {
    path: '/gis-gaode',
    name: 'gis-gaode',
    component: () => import('../views/gis-gaode.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
